import React from 'react'

import appLogo from '../images/mh.png'

import appleStoreBadge from '../images/apple_store_badge.svg'
import googlePlayBadge from '../images/google_play_badge.png'

import coverImage from '../images/banner_img2.png'
import hopperDryer from '../images/hopperdryer.jpg'
import injectionModeling from '../images/injection-molding-machine.jpg'
import cursher from '../images/crusher.jpg'

// endorsement images
import dreamhub_filled from '../images/dreamhub_filled.png'
import voiceqna_filled from '../images/voiceqna_fill_v2_gradient.png'
import voicemirror_filled from '../images/voice_mirror_v_1024.png'
import bazipaipai_unfilled from '../images/bazipaipai_64.png'
import spindrifthome_filled from '../images/sh_unfill_1024.png'


// section images
import guitar from '../images/guitar.jpg'
import event_phones from '../images/event_phones.jpeg'
import foggy_blue from '../images/foggy_blue.jpg'
import purple_phones from '../images/purple_phones.png'
import purple_light from '../images/purple_light.jpg'
import concert_classic from '../images/concert_classic.jpg'

import discordImage from '../images/discord.png'

export const initialState = {
    // when in dev, change appURL to local url
    // appURL: 'http://localhost:3000',  
    // when in production, change appURL to real url
    appURL: 'https://mhnihonellp.com/',

    appLogo: appLogo,
    appName: '',

    coverTitle: 'We Deal In All Kind Of Injection Molding Machines And Accessories',
    coverText: '',
    appleStoreBadge: appleStoreBadge,
    appleStoreLink: 'https://apps.apple.com/us/app/amplichat/id1499570373',
    googlePlayBadge: googlePlayBadge,
    googlePlayLink: 'https://apps.apple.com/us/app/amplichat/id1499570373',
    address : 'Plot No. 4c-67, 4-C-8 Commercial Area, Nazimabad No 4, Karachi',
    coverImage: hopperDryer,

    endorsementTitle: `Hangout with your favorite people on your favorite apps`,
    endorsementText: `AmpliChat powers conversations within DreamHub, VoiceQnA, VoiceMirror, BaZiPaiPai, and SpindriftHome.`,
    endorsementList: [
        {
            title: `DreamHub: Visualized Stories`,
            titleColor: `orangeRed`,
            image: coverImage,
            URL: `https://dreamhub.app`,
        },
        {
            title: `VoiceQnA: Speak a New Language`,
            titleColor: `forestGreen`,
            image: coverImage,
            URL: `https://voiceqna.com`,
        },
        {
            title: `VoiceMirror: Travel Translator`,
            titleColor: `blue`,
            image: coverImage,
            URL: `https://voiceqna.com/mirror`,
        },
        {
            title: `BaZiPaiPai: Know Your Destiny`,
            titleColor: `black`,
            image: coverImage,
            URL: `https://bazipaipai.com`,
        },
        {
            title: `SpindriftHome: HOA Management`,
            titleColor: `orangeRed`,
            image: coverImage,
            URL: `https://spindrifthome.com`,
        },
    ],

    sectionList: [
        {
            'title': `Hopper Dryer`,
            'text': `A hopper dryer is a vital machine used in plastics processing to remove moisture from plastic resin materials. It works by circulating hot air through a hopper filled with plastic pellets or granules, effectively drying them before they are processed further. This moisture removal process is crucial for preventing defects in finished plastic products. Hopper dryers contribute significantly to maintaining consistent product quality and efficiency in plastic manufacturing processes like injection molding and extrusion.`,
            'image': hopperDryer,
        },
        {
            'title': `Injection Molding`,
            'text': `An injection molding machine melts plastic pellets and injects the molten material into a mold, where it cools and solidifies to form a desired plastic part. This process is efficient for mass production of plastic components used in various industries, ranging from automotive and electronics to consumer goods and medical devices. Injection molding machines are essential for producing complex and precise plastic parts with consistent quality and reliability.`,
            'image': injectionModeling,
        },
        {
            'title': `Crusher`,
            'text': `A crusher machine is used to reduce the size of large rocks or other materials into smaller pieces or gravel. It operates by applying mechanical force, such as compression or impact, to break down the material. Crushers are essential in mining, construction, and recycling industries for processing raw materials efficiently.`,
            'image': cursher,
        },
        // {
        //     'title': `Chat with Attendees`,
        //     'text': `Don't let social anxiety get in the way of enjoying your favorite events and concerts! With our app, you can chat with others and make new friends in a safe, welcoming environment.`,
        //     'image': coverImage,
        // },
        // {
        //     'title': `Discover New Events and Friends`,
        //     'text': `Our app is the perfect way to enhance your experience at events and concerts! Connect with others, share your thoughts and opinions, and discover new artists and events to love.`,
        //     'image': coverImage,
        // },
        // {
        //     'title': `Connect with Concert Fans`,
        //     'text': `Whether you're a seasoned concert-goer or a first-time attendee, our app is the perfect way to connect with others and make the most of your experience. Download now and start chatting!`,
        //     'image': coverImage,
        // },
    ],

    discordImage: discordImage,
    discordLink: 'https://discord.com/invite/aFQPYyAVDq',
}

const initialContext = {
    state: initialState,
    dispatch: () => null,
}

export const Context = React.createContext(initialContext)
